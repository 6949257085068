
import { computed, defineComponent, ref } from "vue";
import { LoadFilterObjects } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import Cards from "@/components/base/common/Cards.vue";
import CaseCard from "@/views/case/CaseCard.vue";

export default defineComponent({
  name: "ActiveCases",
  components: {CaseCard, Cards},
  props: {
    policyId: {}
  },
  setup(props) {
    const filterObject = ref({policyId: props.policyId, status: 'ACTIVE'})
    const page = computed(() => store.state.PolicyModule.activeCases)
    return {
      ...LoadFilterObjects(Actions.LOAD_POLICY_ACTIVE_CASES, filterObject.value, ['insured', 'client', 'policy']),
      page,
    }
  }
})
