
import { computed, defineComponent, onMounted, onUpdated, ref } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import CaseService from "@/core/services/CaseService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { removeLinkActive } from "@/core/helpers/dom";
import CaseCard from "@/views/case/CaseCard.vue";
import { LoadFilterObjects } from "@/core/composite/composite";
import Cards from "@/components/base/common/Cards.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import ActiveCases from "@/views/policy/case/ActiveCases.vue";
import CloseCases from "@/views/policy/case/CloseCases.vue";

export default defineComponent({
  name: "Cases",
  components: {CloseCases, ActiveCases, QuickAction, Cards, CaseCard},
  props: {
    id: {type: String}
  },
  setup(props) {
    const store = useStore()
    const filterObject = ref({policyId: props.id})
    const policy = computed(() => store.state.PolicyModule.policy)
    const cases = computed(() => store.state.PolicyModule.cases)
    onUpdated(() => {
      removeLinkActive()
    })
    onMounted(() => {
      removeLinkActive()
    })
    return {
      ...LoadFilterObjects(Actions.LOAD_POLICY_CASES, filterObject.value, ['insured', 'client', 'policy']),
      store,
      policy,
      cases
    }
  },
  methods: {
    onEditCase(caze: any) {
      this.store.dispatch(Actions.EDIT_CASE, caze);
    },

    onDeleteCase(id: string) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await CaseService.delete(id).then(() => {
            this.store.dispatch(Actions.LOAD_POLICY_CASES, this.id)
          })
        }
      })

    }
  }
})
