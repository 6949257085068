
import { computed, defineComponent, ref } from "vue";
import store from "@/store";
import { LoadFilterObjects } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import Cards from "@/components/base/common/Cards.vue";
import CaseCard from "@/views/case/CaseCard.vue";

export default defineComponent({
  name: "CloseCases",
  components: {CaseCard, Cards},
  props:{
    policyId:{},
  },
  setup(props) {
    const filterObject = ref({policyId: props.policyId, status: 'CLOSED'})
    const page = computed(() => store.state.PolicyModule.closedCases)
    return {
      ...LoadFilterObjects(Actions.LOAD_POLICY_CLOSED_CASES, filterObject.value, ['insured', 'client', 'policy']),
      page,
    }
  }
})
